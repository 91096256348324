// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function userToJson(user) {
  return Json_Encode$JsonCombinators.object([[
                "email",
                user.email
              ]]);
}

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "user",
                userToJson(req.user)
              ]]);
}

var Req = {
  userToJson: userToJson,
  toJson: toJson
};

function exec(body, unlockPath) {
  return Rest.fetchRoot(unlockPath, {
              NAME: "Post",
              VAL: toJson(body)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
