// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Api_Session from "../../../../api/sessions/Api_Session.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as EmailValidator from "email-validator";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as SessionUserUnlockFormScss from "./SessionUserUnlockForm.scss";

var css = SessionUserUnlockFormScss;

var validators = {
  email: {
    strategy: "OnFirstChange",
    validate: (function (input) {
        if (EmailValidator.validate(input.email)) {
          return {
                  TAG: "Ok",
                  _0: input.email
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "A valid email is required"
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          email: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            email: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.email;
  var match$1;
  match$1 = typeof match !== "object" ? validators.email.validate(input) : match._0;
  if (match$1.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              email: match$1._0
            },
            fieldsStatuses: {
              email: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              email: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurEmailField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.email, validators.email, (function (status) {
                          return {
                                  email: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateEmailField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.email, state.submissionStatus, validators.email, (function (status) {
                                    return {
                                            email: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateEmail: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateEmailField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurEmail: (function () {
              dispatch("BlurEmailField");
            }),
          emailResult: Formality.exposeFieldResult(state.fieldsStatuses.email),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.email;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var SessionUnlockForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  success: undefined,
  error: undefined
};

function SessionUserUnlockForm(props) {
  var unlockPath = props.unlockPath;
  var initialInput = React.useMemo((function () {
          return {
                  email: ""
                };
        }), []);
  var match = Hooks.useReducer(initialState$1, (function (param, action) {
          if (action.TAG === "SetSuccessMessage") {
            return {
                    TAG: "Update",
                    _0: {
                      success: action._0,
                      error: undefined
                    }
                  };
          }
          var message = action._0;
          return {
                  TAG: "Update",
                  _0: {
                    success: message,
                    error: message
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          console.log(output, cb);
          $$Promise.wait(Api_Session.unlock({
                    user: {
                      email: output.email
                    }
                  }, unlockPath), (function (x) {
                  if (x.TAG === "Ok") {
                    dispatch({
                          TAG: "SetSuccessMessage",
                          _0: "An email will be sent to you if your account exists and it is locked."
                        });
                    return cb.notifyOnSuccess({
                                email: ""
                              });
                  }
                  SentryLogger.error1({
                        rootModule: "SessionUserUnlockForm",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "SessionUserUnlockForm.make"
                      }, "SessionUnlockUser::Error", [
                        "Error",
                        x._0
                      ]);
                  dispatch({
                        TAG: "SetErrorMessage",
                        _0: "Something went wrong. Please try again."
                      });
                  cb.notifyOnFailure();
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var x = state.success;
  var x$1 = state.error;
  var match$1 = form.emailResult;
  var tmp;
  tmp = match$1 !== undefined && match$1.TAG === "Ok" ? false : true;
  return JsxRuntime.jsxs("form", {
              children: [
                x !== undefined ? JsxRuntime.jsx("div", {
                        children: x,
                        className: css.success
                      }) : null,
                x$1 !== undefined ? JsxRuntime.jsx("div", {
                        children: x$1,
                        className: css.error
                      }) : null,
                JsxRuntime.jsx(TextField.make, {
                      id: "resend-unlock-instructions",
                      value: form.input.email,
                      placeholder: "your.email@example.com",
                      onChange: (function ($$event) {
                          form.updateEmail((function (param, value) {
                                  return {
                                          email: value
                                        };
                                }), $$event.target.value);
                        })
                    }),
                fieldError(form.emailResult),
                JsxRuntime.jsx(Button.make, {
                      size: "MD",
                      color: "Primary",
                      expanded: false,
                      disabled: form.submitting,
                      visuallyDisabled: tmp,
                      busy: form.submitting,
                      submit: true,
                      children: "Send Instructions"
                    })
              ],
              className: css.unlockForm,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = SessionUserUnlockForm;

export {
  css ,
  SessionUnlockForm ,
  initialState$1 as initialState,
  make ,
}
/* css Not a pure module */
