// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SessionUserUnlockForm from "./form/SessionUserUnlockForm.res.js";
import * as SessionUserUnlockScss from "./SessionUserUnlock.scss";

var css = SessionUserUnlockScss;

function SessionUserUnlock$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.title,
                      children: "Send Unlock Instructions"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Don't have an account? ",
                        JsxRuntime.jsx("a", {
                              children: "Sign Up",
                              href: Routes_User.signUp
                            })
                      ],
                      className: css.signUp
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(SessionUserUnlockForm.make, {
                            unlockPath: props.unlockPath
                          }),
                      className: css.formContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("a", {
                              children: "Sign In",
                              className: css.sessionLink,
                              href: Routes_User.signIn
                            }),
                        JsxRuntime.jsx("a", {
                              children: "Forgot Password?",
                              className: css.sessionLink,
                              href: Routes_User.Session.passwordNew
                            })
                      ],
                      className: css.sessionLinks
                    })
              ],
              className: css.unlockContainer
            });
}

var $$default = SessionUserUnlock$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
